import React, {Suspense, lazy} from "react";
import {BrowserRouter, Route, Routes, Navigate, Outlet} from "react-router-dom";

const Login = lazy(() => import("./Pages/Common/Login"));
const Profile = lazy(() => import("./Pages/Admin/Profile"));
const Project = lazy(() => import("./Pages/Admin/Project"));
const AssignProject = lazy(() => import("./Pages/Admin/AssignProject"));
const User = lazy(() => import("./Pages/Admin/User"));
const MyProjectList = lazy(() => import("./Pages/User/MyProjectList"));
const WorkList = lazy(() => import("./Pages/Admin/WorkList"));

const Authorization = () => {
	let isAuthenticated = localStorage.getItem("rtdhourtoken");
	return isAuthenticated !== undefined && isAuthenticated !== "" && isAuthenticated !== null ? <Outlet /> : <Navigate to={"/login"} />;
};

export default function RoutesMain() {
	return (
		<BrowserRouter>
			<Suspense
				fallback={
					<div>
						<div className="loading">
							<img src="https://cdn.rentechdigital.com/common_files/swipecart-app/other/logo-short.png" className="img-fluid" alt="Rentech Tracker" />
						</div>
					</div>
				}
			>
				<Routes>
					<Route path="/" exact element={<Login />} />
					<Route path="/login" exact element={<Login />} />
					<Route element={<Authorization />}>
						<Route path="/my-project-list" exact element={<MyProjectList />} />
						<Route path="/profile" exact element={<Profile />} />
						<Route path="/user" exact element={<User />} />
						<Route path="/user-worklist" exact element={<WorkList />} />
						<Route path="/project" exact element={<Project />} />
						<Route path="/assign-project" exact element={<AssignProject />} />
					</Route>
					<Route path="*" element={<Login />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
}
