import React from "react";
import RoutesMain from "./RoutesMain";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "../src/css/style.scss";
import { ToastContainer } from "react-toastify";
import { UserStore } from "./contexts/UserContext";

function App() {
	return (
		<UserStore>
			<ToastContainer autoClose={1000} theme="dark" position="top-right" />
			<RoutesMain />
		</UserStore>
	);
}

export default App;
