let LIVE = Number(process.env.REACT_APP_LIVE);
let api_base_url;
if (LIVE === 1) {
	api_base_url = process.env.REACT_APP_LIVE_API_URL;
} else {
	api_base_url = process.env.REACT_APP_LOCAL_API_URL;
}
export const API_URL = api_base_url;
export const CDN_PATH = "https://cdn.rentechdigital.com/common_files/rentech/employee/";
export const API_PATH = {
	login: API_URL + "auth/login",
	RefreshToken: API_URL + "auth/refresh-token",
	getuserlist: API_URL + "user/get-all-user",
	adduser: API_URL + "user/add-user",
	ChangeUserPasswordAdmin: API_URL + "user/update-password",
	updateUserById: API_URL + "user/update-user",
	GetUSerById: API_URL + "user/get-user-by-id",
	userDetail: API_URL + "user/get-user",
	getProjectlist: API_URL + "project/list-project",
	work: API_URL + "project/work",
	addProject: API_URL + "project/upsert-project",
	UsergetProjectlist: API_URL + "project/list-work",
	listallwork: API_URL + "project/list-all-work",
	modifywork: API_URL + "project/modify-work",
};

export const errorContainer = (form, field) => {
	return form.touched[field] && form.errors[field] ? <span className="error text-danger">{form.errors[field]}</span> : null;
};

export const formAttr = (form, field) => ({onBlur: form.handleBlur, onChange: form.handleChange, value: form.values[field]});
