import {API_PATH} from "./Const";
export const FetchPostApi = async (path, body) => {
	let token_Data = "";
	if (localStorage.getItem("rtdhourtoken")) {
		token_Data = localStorage.getItem("rtdhourtoken");
	}
	const options = {method: "POST", headers: {Authorization: token_Data, "Content-Type": "application/json"}, body: JSON.stringify(body)};
	const PostApiData = await fetch(path, options)
		.then(async (response) => {
			if (response.status === 403) {
				await fetch(API_PATH.RefreshToken, options)
					.then(async (result) => {
						if (result.status === 200) {
							let newtoken = await result.json();
							localStorage.setItem("rtdhourtoken", "Bearer " + newtoken.token);
							window.location.reload();
						} else {
							localStorage.removeItem("rtdhourtoken");
							window.location.href = "/";
						}
					})
					.catch((err) => {
						console.log(err);
						return err.response;
					});
			} else {
				return response;
			}
		})
		.catch((err) => {
			console.log(err);
			return err.response;
		});

	return PostApiData;
};
