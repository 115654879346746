import React, {useEffect, useState, createContext} from "react";
import {FetchPostApi} from "../Api/apiServices";
import {toast} from "react-toastify";
import {API_PATH} from "../Api/Const";

const Context = createContext("");

export const UserStore = ({children}) => {
	let [user_role_id, set_user_role_id] = useState("");
	let [user_detail, set_user_detail] = useState("");

	useEffect(() => {
		let token_Data = localStorage.getItem("rtdhourtoken");
		if (token_Data !== null) {
			if (user_detail === "") {
				set_login_user_details();
			}
			if (user_role_id === "") {
				set_user_roll();
			}
		}
	}, [user_role_id, user_detail]);

	const set_user_roll = async () => {
		let token = localStorage.getItem("rtdhourtoken");
		if (token !== null) {
			let userRole = await parseJwt(token);
			set_user_role_id(userRole.role);
		}
	};

	const set_user_role = (role) => {
		set_user_role_id(role);
	};

	const parseJwt = (token) => {
		return new Promise((resolve, reject) => {
			var base64Url = token.split(".")[1];
			var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
			var jsonPayload = decodeURIComponent(
				window
					.atob(base64)
					.split("")
					.map(function (c) {
						return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join("")
			);
			resolve(JSON.parse(jsonPayload));
		});
	};

	const set_login_user_details = async () => {
		let result = await FetchPostApi(API_PATH.userDetail);
		let UserList = await result.json();
		if (result.status === 200) {
			set_user_detail(UserList.data);
		} else {
			toast.error(UserList.message);
		}
	};

	return (
		<Context.Provider
			value={{
				...{
					user_role_id,
					user_detail,
				},
				set_user_role,
				parseJwt,
				set_login_user_details,
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default Context;
